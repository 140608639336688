<template>
  <div class="students-list wrap px-3 py-5">
    <div class="d-flex flex-wrap align-top mb-7">
      <div class="h1">Students</div>
      <v-spacer></v-spacer>
      <v-text-field
        class="pt-0 mt-0 students-list_search"
        v-model="search"
        @keyup="getData"
        @change="getData"
        placeholder="Search by name"
        prepend-inner-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        color="blue"
        hide-details
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </div>
    <v-data-table class="d-sm-block d-none" hide-default-footer fixed-header :headers="headers" :items="data.results">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" height="52" class="link" @click="openItem(item.id)">
            <td class="url--text text-decoration-underline">{{ item.first_name }} {{ item.last_name }}</td>
            <td>
              <div class="clip">{{ item.next_lesson.title }}</div>
              <div class="font-weight-regular clip">{{ item.next_lesson.description }}</div>
            </td>
            <td>
              <div>{{ new Date(item.next_lesson_at).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}</div>
              <div>{{ new Date(item.next_lesson_at).toLocaleTimeString('en-GB').substring(0, 5) }}</div>
            </td>
            <td class="text-end">
              <v-btn @click.stop="openMsg(item.id)" depressed width="100" height="28" color="primary">
                <img height="16" class="me-1" src="@/assets/icon/msgBtn.svg" /> MESSAGE
              </v-btn>
              <!-- <v-btn v-if="item.next_lesson" depressed min-width="28" height="28" class="primary px-0 ms-2">
                <img height="16" src="@/assets/icon/video.svg" />
              </v-btn> -->
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer v-if="data.pages > 1">
        <v-divider></v-divider>
        <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
          <div>
            <v-btn icon color="secondary" class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
            <v-btn icon color="secondary" class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-row>
      </template>
    </v-data-table>
    <div class="d-sm-none">
      <div v-for="item in data.results" :key="item.id" @click="openItem(item.id)" class="px-4 pt-2">
        <div class="d-flex justify-space-between my-1">
          <div>Name</div>
          <div class="url--text text-decoration-underline">{{ item.first_name }} {{ item.last_name }}</div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Next lesson</div>
          <div class="text-end">
            <div>{{ item.next_lesson.title }}</div>
            <div class="font-weight-regular">{{ item.next_lesson.description }}</div>
          </div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div>Date</div>
          <div class="text-end">
            <div>
              {{ new Date(item.next_lesson_at).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}
              {{ new Date(item.next_lesson_at).toLocaleTimeString('en-GB').substring(0, 5) }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between my-1">
          <div></div>
          <div class="text-end">
            <v-btn depressed width="100" height="28" color="primary">
              <img height="16" class="me-1" src="@/assets/icon/msgBtn.svg" /> MESSAGE
            </v-btn>
          </div>
        </div>
        <v-divider class="mt-2"></v-divider>
      </div>
      <v-row class="mt-2 pb-2 px-10" align="center" justify="end" v-if="data.pages > 1">
        <div>
          <v-btn icon color="secondary" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
          <v-btn icon color="secondary" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      search: this.$route.query.src || '',
      page: this.$route.query.page || 1,
      headers: [
        { text: 'Name', value: 'first_name', sortable: false },
        { text: 'Next lesson', value: 'next_lesson', sortable: false },
        { text: 'Date', value: 'next_lesson_at', sortable: false },
        { text: '', align: 'end', value: 'action', sortable: false },
      ],
      statusError: null,
    };
  },
  methods: {
    async getData() {
      const data = new Object();
      data.search = this.search;
      data.page = this.page;
      await this.$store.dispatch('getStudentsList', data).catch((e) => {
        this.getDataError(e);
      });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      }
    },
    clearSearch() {
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    openItem(id) {
      this.$router.push(`students/${id}`);
    },
    openMsg(id) {
      this.$store.dispatch('newMsg', { student: id });
    },
  },
  computed: {
    data() {
      return this.$store.getters.studentsList;
    },
  },
  destroyed() {
    this.$store.dispatch('setStudentsList', {});
  },
};
</script>

<style lang="scss">
.students-list {
  &_search {
    max-width: 200px !important;
  }
}
</style>
